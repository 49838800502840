import {
	TEXT_ALPHA,
	TEXT_REQUIRED,
	TEXT_MAX_LENGTH,
	TEXT_EMAIL_DONT_MATCH,
	TEXT_NUMERIC,
	TEXT_EMAIL,
	TEXT_POSTAL_CODE,
	TEXT_MIN_VALUE,
	TEXT_CNO_INVALID,
	TEXT_CNAE_INVALID,
	TEXT_INVALID_PHONE_NUMBER,
	TEXT_INVALID_DNI,
} from '@/texts';

export const getErrorText = (model) => {
	const { $params } = model;

	if (!model.$anyError) {
		return '';
	}
	if ($params.alpha && !model.alpha) {
		return TEXT_ALPHA;
	}
	if ($params.alphaNum && !model.alphaNum) {
		return TEXT_ALPHA;
	}
	if (
		($params.companyNameValidator && !model.companyNameValidator) ||
		($params.customAddressValidator && !model.customAddressValidator) ||
		($params.customAddressFloorValidator && !model.customAddressFloorValidator)
	) {
		return TEXT_ALPHA;
	}
	if (($params.cnae && !model.cnae) || (model.cnae && model.$invalid)) {
		return TEXT_CNAE_INVALID;
	}
	if (($params.cno && !model.cno) || (model.cno && model.$invalid)) {
		return TEXT_CNO_INVALID;
	}
	if ($params.required && !model.required) {
		return TEXT_REQUIRED;
	}
	if ($params.numeric && !model.numeric) {
		return TEXT_NUMERIC;
	}
	if ($params.email && !model.email) {
		return TEXT_EMAIL;
	}
	if ($params.phoneMinLength && !model.phoneMinLength) {
		return TEXT_INVALID_PHONE_NUMBER;
	}
	if ($params.phoneNumber && !model.phoneNumber) {
		return TEXT_INVALID_PHONE_NUMBER;
	}
	if ($params.minValue && !model.minValue) {
		return TEXT_MIN_VALUE;
	}
	if ($params.postalCode && !model.postalCode) {
		return TEXT_POSTAL_CODE;
	}
	if ($params.maxLength && !model.maxLength) {
		return TEXT_MAX_LENGTH($params.maxLength.max);
	}
	if ($params.validDNI && !model.validDNI) {
		return TEXT_INVALID_DNI;
	}
	if ($params.sameAsEmail && !model.sameAsEmail) {
		return TEXT_EMAIL_DONT_MATCH;
	}
	if (!model.accepted) {
		return TEXT_REQUIRED;
	}

	return '';
};

export const mapErrorFields = (fields) => {
	return fields.reduce(
		(reducer, name) => ({
			...reducer,
			[`${name}Error`]: ({ $v: { [name]: model } }) => getErrorText(model),
		}),
		{}
	);
};
