export const TEXT_REQUIRED = 'This field is required';
export const TEXT_MAX_LENGTH = (max) => `Enter a maximum of ${max} characters.`;
export const TEXT_EMAIL_DONT_MATCH = 'Email does not match';
export const TEXT_NUMERIC = 'Invalid number';
export const TEXT_EMAIL = 'Invalid email';
export const TEXT_MIN_VALUE =
	'If you are a minor you will not be able to continue with the process.';
export const TEXT_INVALID_PHONE_NUMBER = 'Invalid phone number';
export const TEXT_INVALID_DNI = 'The document does not appear to be valid';
export const TEXT_ALPHA = 'Field contains invalid characters';
export const TEXT_POSTAL_CODE = 'Invalid postal code';
export const TEXT_CNO_INVALID = 'Enter a valid profession';
export const TEXT_CNAE_INVALID = 'Enter a valid activity';
